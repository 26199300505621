@import '~normalize.css/normalize';

html * {
  box-sizing: border-box;
}

// additional rules goes here
img {
  max-width: 100%;
  max-height: 100%;
}
