.app {
  max-width: 1080px;
  margin: 0 auto;

  p {
    font-size: 1.4rem;
  }
}

.field {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  input[type='number'] {
    height: 32px;
    padding: 0 1rem;
    border: 1px solid #000;

    &:focus {
      outline: 0;
    }
  }

  button {
    height: 32px;
    padding: .5rem 1rem;
    border: 0;
    border-radius: 0 3px 3px 0;
    background-color: #000000;
    color: #ffffff;
  }
}
