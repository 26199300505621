@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import 'reset';

html {
  font-size: 10px;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  background-color: #EEE;
  color: #414141;
  padding: 0 1.5rem;
}

.fade-in {
  opacity: 0;
  animation: fade-in .75s ease;
  animation-fill-mode: forwards;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.15s;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}